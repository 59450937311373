(function () {
  // Server-side routes to exclude
  var serverRoutes = [
    "/category",
    "/categories",
    "/cf-login-error",
    "/users",
    "/tags",
    "/account",
    "/admin",
    "/affiliate",
    "/affiliates",
    "/terms",
  ];

  // Define UI8 Application
  angular
    .module("UI8SPA", [
      "ngResource",
      "ngCookies",
      "express-csrf",
      "ui.router",
      "UI8.filters",
      "UI8.services",
      "UI8.resources",
      "UI8.directives",
      "UI8.controllers",
      "UI8.components",
      "credit-cards",
      "ngFileUpload",
      "relativeDate",
      "ngSanitize",
    ])
    .config([
      "$locationProvider",
      function ($locationProvider) {
        $locationProvider.html5Mode(true);
      },
    ])
    .config(function ($httpProvider) {
      $httpProvider.defaults.headers.common["X-Requested-With"] =
        "XMLHttpRequest";
      $httpProvider.defaults.withCredentials = true;
    })
    .config(function ($stateProvider) {
      var home = {
        name: "home",
        url: "^/",
        component: "home",
        resolve: {
          data: function (ProductService) {
            return ProductService.homeDigest();
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: revealPage,
      };

      var login = {
        name: "accountLogin",
        url: "^/account/login",
        component: "accountLogin",
        resolve: {
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: function (user) {
          if (user) return (window.location = "/account");
          revealPage();
        },
      };

      var signup = {
        name: "accountSignup",
        url: "^/account/signup",
        component: "accountSignup",
        resolve: {
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: function (user) {
          if (user) return (window.location = "/account");
          revealPage();
        },
      };

      var forgot = {
        name: "accountForgot",
        url: "^/account/forgot",
        component: "accountForgot",
        resolve: {
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: function (user) {
          if (user) return (window.location = "/account");
          revealPage();
        },
      };

      var authors = {
        name: "openShop",
        url: "^/authors",
        component: "openShop",
        resolve: {
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: scrollTopAndReveal,
      };

      var affiliates = {
        name: "Affiliates",
        url: "^/affiliates",
        component: "affiliates",
        resolve: {
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: scrollTopAndReveal,
      };

      var pass = {
        name: "pass",
        url: "^/products/all-access-pass",
        component: "productPage",
        resolve: {
          data: function (ProductService) {
            console.log("app_spa -> product page data");
            return ProductService.passPage();
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: revealPage,
      };

      var sale = {
        name: "sale",
        url: "^/sale",
        component: "category",
        resolve: {
          data: function (CategoryService) {
            var page = sessionStorage.getItem("page:sale") || 1;
            return CategoryService.findBySlug("sale", {
              page: page,
              ff: page > 1 ? true : false,
            });
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: revealPage,
      };

      var trending = {
        name: "trending",
        url: "^/trending",
        component: "category",
        resolve: {
          data: function (CategoryService) {
            var page = sessionStorage.getItem("page:sale") || 1;
            return CategoryService.findBySlug("trending", {
              page: page,
              ff: page > 1 ? true : false,
            });
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: revealPage,
      };

      var topProducts = {
        name: "topProducts",
        url: "^/category/featured-products",
        component: "topProducts",
        resolve: {
          data: function (CategoryService) {
            return CategoryService.findBySlug("featured-products", {});
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: scrollTopAndReveal,
      };

      var categories = {
        name: "categories",
        url: "/categories/{slug}",
        component: "category",
        resolve: {
          data: function (CategoryService, $transition$) {
            var slug = $transition$.params().slug;
            var page = sessionStorage.getItem("page:" + slug) || 1;
            return CategoryService.findBySlug(slug, {
              page: page,
              ff: page > 1 ? true : false,
            });
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
      };

      var category = {
        name: "category",
        url: "/category/{slug}",
        component: "category",
        resolve: {
          data: function (CategoryService, $transition$) {
            var slug = $transition$.params().slug;
            var page = sessionStorage.getItem("page:" + slug) || 1;
            return CategoryService.findBySlug(slug, {
              page: page,
              ff: page > 1 ? true : false,
            });
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
      };

      var purchases = {
        name: "purchases",
        url: "^/account/purchases",
        component: "accountPurchases",
        resolve: {
          data: function (SessionService) {
            return SessionService.purchases();
          },
        },
        onEnter: function (data) {
          if (!data.user)
            return (window.location =
              "/account/signin?from=_account_purchases");
          scrollTopAndReveal();
        },
      };

      var profile = {
        name: "profile",
        url: "/users/{id}",
        component: "userProfile",
        resolve: {
          data: function (UserService, $transition$) {
            return UserService.show($transition$.params().id);
          },
          followers: function (UserService, $transition$) {
            return UserService.followers($transition$.params().id);
          },
          following: function (UserService, $transition$) {
            return UserService.following($transition$.params().id);
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: scrollTopAndReveal,
      };

      var authorProfile = {
        name: "authorProfile",
        url: "/{id}/products",
        component: "userProfile",
        resolve: {
          data: function (UserService, $transition$) {
            return UserService.show($transition$.params().id);
          },
          followers: function (UserService, $transition$) {
            return UserService.followers($transition$.params().id);
          },
          following: function (UserService, $transition$) {
            return UserService.following($transition$.params().id);
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: scrollTopAndReveal,
      };

      var authorPage = {
        name: "authorPage",
        url: "/{id}",
        component: "userProfile",
        resolve: {
          data: function (UserService, $transition$) {
            return UserService.show($transition$.params().id);
          },
          followers: function (UserService, $transition$) {
            return UserService.followers($transition$.params().id);
          },
          following: function (UserService, $transition$) {
            return UserService.following($transition$.params().id);
          },
          user: function (SessionService) {
            return SessionService.user();
          },
        },
        onEnter: scrollTopAndReveal,
      };

      var userSettings = {
        name: "userSettings",
        url: "^/account/settings",
        component: "accountSettings",
        resolve: {
          data: function (AccountService) {
            return AccountService.settings();
          },
        },
        onEnter: function (data) {
          if (!data.user)
            return (window.location = "/account/signin?from=_account_settings");
          scrollTopAndReveal();
        },
      };

      $stateProvider
        .state(home)
        .state(login)
        .state(signup)
        .state(forgot)
        .state(authors)
        .state(affiliates)
        .state(pass)
        .state(sale)
        .state(trending)
        .state(topProducts)
        .state(category)
        .state(categories)
        .state(purchases)
        .state(authorProfile)
        .state(profile)
        .state(userSettings)
        .state(authorPage);

      function scrollToTop() {
        window.scrollTo(0, 0);
      }

      function revealPage() {
        angular
          .element(document.querySelector(".page-loader-container"))
          .addClass("ng-hide");
        angular
          .element(document.querySelector(".body-container"))
          .removeClass("ng-hide");
      }

      function scrollTopAndReveal() {
        scrollToTop();
        revealPage();
      }
    })
    .config(function ($urlRouterProvider) {
      $urlRouterProvider.when("/category/all", "/category/featured-products");
      $urlRouterProvider.when("/categories/all", "/category/featured-products");
      $urlRouterProvider.when(
        "/category/top-products",
        "/category/featured-products"
      );
      $urlRouterProvider.when(
        "/categories/top-products",
        "/category/featured-products"
      );
      $urlRouterProvider.when(
        "/categories/featured-products",
        "/category/featured-products"
      );
      $urlRouterProvider.when(
        "/products/ultimate-pass",
        "/products/all-access-pass"
      );
      $urlRouterProvider.when(
        "/product/ultimate-pass",
        "/products/all-access-pass"
      );
      $urlRouterProvider.when(
        "/product/unlimited-pass",
        "/products/all-access-pass"
      );
      $urlRouterProvider.when(
        "/product/ui8/all-access-pass",
        "/products/all-access-pass"
      );
      $urlRouterProvider.when(
        "/products/ui8/all-access-pass",
        "/products/all-access-pass"
      );
      $urlRouterProvider.when("/account", "/account/purchases");
      for (var i = 0; i < serverRoutes.length; i++) {
        var serverPath = serverRoutes[i];
        $urlRouterProvider.when(serverPath, function () {
          location.reload();
        });
      }
      $urlRouterProvider.otherwise(function () {
        location.reload();
      });
    })
    .config([
      "$compileProvider",
      function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
      },
    ])
    .run(function (
      $rootScope,
      SessionService,
      $transitions,
      $location,
      $timeout
    ) {
      if ("scrollRestoration" in history) {
        history.scrollRestoration = "manual";
      } else {
        window.addEventListener("unload", function () {
          //set scroll position to the top of the page.
          window.scrollTo(0, 0);
        });
      }

      if (!$rootScope.user) {
        SessionService.setCurrentUser();
      }

      function openSearch(letter) {
        $rootScope.showSearch = true;
        if (letter != null) {
          $rootScope.searchTerm = letter;
          sessionStorage.setItem("search:term", letter);
        }
        $timeout(function () {
          $rootScope.$digest();
        });
        $location.search("search", 1);
        $("body").css("overflow-y", "hidden");
      }

      window.openSearch = openSearch;

      document.addEventListener("keydown", function (e) {
        var invalids = ["input", "select", "textarea"];

        if (invalids.indexOf(e.target.nodeName.toLowerCase()) !== -1) return;

        if ($rootScope.showSearch) {
          $("#search-input").focus();
        }

        // Ignore keypresses when Cmd, Ctrl, or Alt are pressed
        if (e.altKey || e.ctrlKey || e.metaKey) return;

        // Only react to numbers and letters
        if (e.keyCode < 48 || e.keyCode > 90) return;

        var letter = String.fromCharCode(e.keyCode);
        if (!e.shiftKey) letter = letter.toLowerCase();

        if (!$rootScope.showSearch) {
          return openSearch(letter);
        }
      });

      $transitions.onSuccess({}, function () {
        if (window.Intercom) Intercom("update");
        if ($location.search().search) openSearch();
        if ($location.search().support) Intercom("show");
      });
    }); /*.run(function($uiRouter) {
      var Visualizer = window['ui-router-visualizer'].Visualizer;
      var pluginInstance = $uiRouter.plugin(Visualizer);
  });*/

  // Application Namespaces
  angular.module("UI8.filters", []);
  angular.module("UI8.services", []);
  angular.module("UI8.resources", []);
  angular.module("UI8.directives", []);
  angular.module("UI8.controllers", []);
  angular.module("UI8.components", []);
})();
